<template>
    <div>
        <div
            v-if="isLightbox"
            class="h-[35px]"
        />

        <div
            class="bg-gray-100 py-2 text-center text-gray-600"
            :class="[
                isLightbox ? 'fixed bottom-0 left-0 right-0 z-20' : '',
                isKiosk ? 'relative' : ''
            ]"
        >
            <button
                v-if="isKiosk"
                type="button"
                class="absolute left-0 top-1/2 flex -translate-y-1/2 transform items-center gap-2 px-4 py-2"
                @click="$emit('verify')"
            >
                <check-circle-icon
                    v-if="kioskName === undefined || kioskName === ''"
                    class="h-6 w-6"
                />
                <shield-check-icon
                    v-else
                    class="h-6 w-6"
                />
                {{ kioskName }}
            </button>

            Powered by
            <nuxt-link
                to="https://www.redpointhq.com"
                target="_blank"
                class="font-semibold hover:underline"
                >Redpoint HQ</nuxt-link
            >&nbsp;<span class="text-xs text-gray-300"
                >&mdash;&nbsp;{{ version.major }}.{{ version.minor }}.{{
                    version.patch
                }}</span
            >
        </div>
    </div>
</template>

<script setup lang="ts">
import { version } from '~/composables/meta'
import NuxtLink from '#app/components/nuxt-link'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { ShieldCheckIcon } from '@heroicons/vue/24/solid'
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

defineProps<{
    kioskName?: string
}>()

defineEmits<{
    (e: 'verify'): void
}>()

const { isLightbox, isKiosk } = useLayoutMode()
</script>
